import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { MdLaunch } from "react-icons/md";
import Layout from "../../components/layout";
import SearchEngineOptimization from "../../components/SearchEngineOptimization";
import "./styles.scss";
import renderOptions from "./rich-text-render-options";

function CaseStudyPage({ data: { caseStudy } }) {
	const {
		title,
		platform,
		siteUrl,
		scopeOfWork,
		industry,
		brief: { brief },
		seoDescription: { seoDescription },
		heroImage,
		body,
	} = caseStudy;

	return (
		<Layout>
			<SearchEngineOptimization
				title={`${title} Case Study`}
				description={seoDescription}
			/>
			<Helmet>
				<script type="application/ld+json">
					{`{
						"@context" : "http://schema.org",
						"@type" : "NewsArticle",
						"headline" : "${title} Case Study",
						"description": "${seoDescription}",
						"image": ["${heroImage.file.url}"]
					}`}
				</script>
			</Helmet>
			<div className="case-study">
				<div
					className="case-study__background-image"
					style={{
						backgroundImage: `linear-gradient(rgba(40, 40, 40, .3), rgba(40, 40, 40, .7)), url(${heroImage.file.url})`,
					}}
				>
					<div className="container">
						<div className="case-study__hero">
							<div>
								<div className="case-study__heading-label">Case Study</div>
								<h1 className="case-study__heading">{title}</h1>
							</div>
							<div>
								<div className="case-study__meta">
									<div className="case-study__meta-item">
										<div className="case-study__meta-heading">Platform</div>
										<div>{platform}</div>
									</div>
									<div className="case-study__meta-item">
										<div className="case-study__meta-heading">
											Scope of Work
										</div>
										<div className="case-study__scope">
											{scopeOfWork.map(scope => (
												<span key={scope}>{scope}</span>
											))}
										</div>
									</div>
									<div className="case-study__meta-item">
										<div className="case-study__meta-heading">Industry</div>
										<div>{industry}</div>
									</div>
								</div>
								<a
									className="case-study__site-url"
									href={`${siteUrl}`}
									rel="noreferrer"
									target="_blank"
								>
									<span>Visit Site</span>
									<span className="visually-hidden">
										(Opens in a new window)
									</span>
									<MdLaunch aria-hidden="true" size={20} />
								</a>
							</div>
						</div>
					</div>
				</div>

				<div className="section section--is-spacious">
					<div className="container container--is-narrow">
						<h2 className="text text--large">Brief</h2>
						<p className="text text--xsmall">{brief}</p>
					</div>
					<div className="container">
						<div className="rich-text">
							{body && renderRichText(body, renderOptions)}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query($slug: String!) {
		caseStudy: contentfulCaseStudy(slug: { eq: $slug }) {
			title
			siteUrl
			platform
			scopeOfWork
			industry
			brief {
				brief
			}
			body {
				raw
				references {
					... on ContentfulAsset {
						contentful_id
						__typename
						title
						description
						gatsbyImageData
					}
				}
			}
			heroImage {
				file {
					url
				}
			}
			seoDescription {
				seoDescription
			}
		}
	}
`;

export default CaseStudyPage;
