import React from "react";
import PolygonHeader from "../../images/polygons/polygon-header-default.svg";

const HeadingLevel2WithPolygon = ({ children }) => {
	return (
		<div className="rich-text__heading-container">
			<div className="polygon">
				<img
					className="polygon__image"
					src={PolygonHeader}
					alt=""
					aria-hidden="true"
				/>
			</div>
			<h2 className="rich-text__heading2">{children}</h2>
		</div>
	);
};

export default HeadingLevel2WithPolygon;
