import React from "react";

const Paragraph = ({ children }) => {
	return (
		<div className="rich-text__paragraph">
			<p>{children}</p>
		</div>
	);
};

export default Paragraph;
