import React from "react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { HeadingLevel2WithPolygon, Paragraph } from "../../components/RichText";

const richTextRenderOptions = {
	renderMark: {
		[MARKS.BOLD]: text => <strong>{text}</strong>,
		[MARKS.ITALIC]: text => <em>{text}</em>,
	},
	renderNode: {
		// h1, h3, h4, h5, h6 formatting options have been disabled in contentful rich text editiing for case studies
		[BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
		[BLOCKS.HEADING_2]: (node, children) => <HeadingLevel2WithPolygon children={children} />,
		[BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
		[BLOCKS.UL_LIST]: (node, children) => (
			<ul className="rich-text__ul-list">{children}</ul>
		),
		[BLOCKS.OL_LIST]: (node, children) => (
			<ul className="rich-text__ol-list">{children}</ul>
		),
		[BLOCKS.EMBEDDED_ASSET]: node => {
			const image = getImage(node.data.target);
			const { title, description } = node.data.target;
			return (
				<div className="rich-text__image">
					<GatsbyImage
						image={image}
						alt={description ? description : title}
					/>
				</div>
			);
		},
	},
};

export default richTextRenderOptions;
